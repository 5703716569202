<template>
    <header class="w-full flex justify-center h-[70px] fixed top-0 z-50 bg-gray-100 items-center">
        <div class="container lg:max-w-[1024px] px-[20px] w-full flex justify-between items-center">
            <div>
                <img src="../assets/img/logo-vaybe-noirp.png" alt="Logo de Vaybe" class="w-[100px]">
            </div>
            <div class="md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M14 5H2V3h12zm0 4H2V7h12zM2 13h12v-2H2z" clip-rule="evenodd"/></svg>
            </div>
            <div class="hidden md:flex">
                <ul class="flex justify-center items-center">
                    <li class="mx-[10px] text-sm font-semibold text-gray-500">Acceuil</li>
                    <li class="mx-[10px] text-sm font-semibold text-gray-500">A propos</li>
                    <li class="mx-[10px] text-sm font-semibold text-gray-500">Solutions</li>
                    <li class="mx-[10px] text-sm font-semibold text-gray-500">Services</li>
                    <li class="mx-[10px] text-sm font-semibold text-gray-500">Blog</li>
                    <li class="mx-[10px] text-sm font-semibold text-gray-500">Contact</li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {

}
</script>

<style>

</style>